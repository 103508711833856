<template>
  <div class="anb-list">
    <div class="d-flex align-center flex-sm-nowrap flex-wrap">
      <div class="text-h5 font-weight-bold">ANB transactions</div>
      <v-spacer></v-spacer>
      <v-card color="accent" flat tile class="ms-sm-3 width100" max-width="250">
        <v-menu offset-y :close-on-content-click="closeMenu">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="field46"
              :value="`${date.start ? new Date(date.start).toLocaleString().substr(0, 10) : 'Select period'} ${
                date.end ? ` - ${new Date(date.end).toLocaleString().substr(0, 10)}` : ''
              }`"
              v-bind="attrs"
              v-on="on"
              placeholder="Select period"
              hide-details
              outlined
              dense
              color="primary"
              height="46"
              readonly
            >
              <template v-slot:prepend-inner>
                <v-img class="mt-2 gray-filter" src="@/assets/icon/calendar.svg"></v-img>
              </template>
            </v-text-field>
          </template>
          <div class="d-flex align-stretch white">
            <v-list>
              <v-list-item
                style="min-height: 34px"
                v-for="(item, i) in datePeriod"
                :key="i"
                link
                @click="(calendar = false), secectDate(i)"
              >
                <v-list-item-title class="text-body-2">{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
            <v-card flat color="white" v-if="calendar">
              <vc-date-picker
                style="border: initial"
                :columns="$screens({ default: 1, lg: 2 })"
                locale="en"
                :input-debounce="500"
                v-model="date"
                :masks="masks"
                :model-config="modelConfig"
                :first-day-of-week="2"
                is-range
                color="blue"
              ></vc-date-picker>
              <div class="text-end pr-3">
                <v-btn @click="getData" depressed color="primary" class="rounded mr-3" width="96" height="30">
                  <span class="text-body-2">Select</span>
                </v-btn>
                <v-btn @click="closeCalendar" outlined depressed color="gray" class="rounded" width="96" height="30">
                  <span class="text-body-2 text--text">Cancel</span>
                </v-btn>
              </div>
            </v-card>
          </div>
        </v-menu>
      </v-card>
      <v-card color="accent" flat tile class="ms-sm-3 width100" max-width="480">
        <v-text-field
          class="field46"
          v-model="search"
          placeholder="Search by ID or customers name"
          prepend-inner-icon="mdi-magnify"
          :append-icon="search ? 'mdi-close' : ''"
          @click:append="clearSearch"
          hide-details
          outlined
          dense
          required
          color="primary"
          height="46"
          @change="(page = 1), getData()"
        ></v-text-field>
      </v-card>
    </div>
    <div class="mt-4">
      <v-card tile flat color="transparent" v-if="!data.result" class="d-flex justify-center align-center" :height="`calc(100vh - 240px)`">
        <div>
          <v-img height="150" contain src="@/assets/img/bad.svg"></v-img>
          <div class="text-center text--text mt-4">Sorry, but nothing found</div>
        </div>
      </v-card>
      <div v-else>
        <v-data-table
          hide-default-footer
          fixed-header
          :headers="headers"
          :items="data.result"
          item-key="id"
          :items-per-page="-1"
          :height="`calc(100vh - 240px)`"
          class="d-sm-block d-none accent"
        >
          <template v-slot:header.status>
            Status
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon class="btn-sort" :class="status != 'all' ? 'primary white--text' : ''">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  :class="status == item.name ? 'primary--text font-weight-bold' : ''"
                  class="text-body-2"
                  link
                  v-for="item in itemsStatus"
                  :key="item.name"
                  @click="(status = item.name), getData()"
                  style="min-height: 36px"
                >
                  {{ item.title }}
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template v-slot:header.created_at>
            Date
            <v-btn
              @click="sortItems('created_at')"
              icon
              class="btn-sort"
              :class="sort.some((i) => i == 'created_at_asc' || i == 'created_at_desc') ? 'primary white--text' : ''"
            >
              <v-icon>{{ sort.some((i) => (i == 'created_at_asc' ? true : false)) ? 'mdi-arrow-down-thin' : 'mdi-arrow-up-thin' }}</v-icon>
            </v-btn>
          </template>
          <template v-if="!data.pages" v-slot:no-data>
            <v-card tile flat color="transparent" class="d-flex justify-center align-center">
              <div>
                <v-img min-height="150" height="220" contain src="@/assets/img/bad.svg"></v-img>
                <div class="text-center text--text mt-4">Sorry, but nothing found</div>
              </div>
            </v-card>
          </template>
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id" class="link" @click="openLink(item.id)" height="64">
                <td :class="role == 'manager' ? '' : 'primary--text'">
                  <span v-if="item.booking_number" @click.stop="role == 'manager' ? '' : openBooking(item.booking_id)">
                    {{ item.booking_number }}
                  </span>
                </td>
                <td :class="role == 'manager' ? '' : 'primary--text'">
                  <span v-if="item.customer" @click.stop="role == 'manager' ? '' : openCustomer(item.customer.id)">
                    {{ item.customer ? `${item.customer.first_name} ${item.customer.last_name}` : '' }}
                  </span>
                </td>
                <td>{{ item.phone_number ? item.phone_number : item.identifier }}</td>
                <td class="text-uppercase">{{ item.amount_currency }} {{ item.amount / 100 }}</td>
                <td class="text-capitalize">{{ item.status }}</td>
                <td>
                  {{
                    new Date(item.created_at).toLocaleString('en-GB', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                    })
                  }}
                </td>
              </tr>
            </tbody>
          </template>
          <template v-slot:footer>
            <v-divider></v-divider>
            <div class="mt-2 pb-2 px-3 d-flex align-center">
              <div class="text--text">Total {{ data.total }}</div>
              <v-spacer></v-spacer>
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
            <v-divider></v-divider>
          </template>
        </v-data-table>
        <div class="d-block d-sm-none">
          <div v-for="item in data.result" :key="item.id" @click="openLink(item.id)" class="px-4 pt-2">
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Booking ID</div>
              <div
                v-if="item.booking_number"
                @click.stop="role == 'manager' ? '' : openBooking(item.booking_id)"
                class="font-weight-medium f18"
                :class="role == 'manager' ? '' : 'primary--text'"
              >
                {{ item.booking_number }}
              </div>
              <div v-else></div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Customer</div>
              <div
                v-if="item.customer"
                @click.stop="role == 'manager' ? '' : openCustomer(item.customer.id)"
                class="font-weight-medium f18"
                :class="role == 'manager' ? '' : 'primary--text'"
              >
                {{ item.customer ? `${item.customer.first_name} ${item.customer.last_name}` : '' }}
              </div>
              <div v-else></div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Phone number/CIF</div>
              <div class="font-weight-medium f18">{{ item.phone_number ? item.phone_number : item.identifier }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Amount</div>
              <div class="font-weight-medium f18 text-truncate text-uppercase">{{ item.amount_currency }} {{ item.amount / 100 }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Status</div>
              <div class="font-weight-medium f18 text-capitalize">{{ item.status }}</div>
            </div>
            <div class="d-flex my-1">
              <div class="opasity--text f18 font-weight-medium label-table">Date</div>
              <div class="font-weight-medium f18">
                {{
                  new Date(item.created_at).toLocaleString('en-GB', {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                  })
                }}
              </div>
            </div>
            <v-divider></v-divider>
          </div>
          <div class="mt-2 pb-2 px-3 d-flex align-center justify-center flex-wrap">
            <div class="text--text">Total {{ data.total }}</div>
            <v-spacer></v-spacer>
            <div class="ms-2">
              <v-btn outlined small min-width="28" color="text" class="mr-1 px-0" @click="formerPage">
                <v-icon>mdi-chevron-left</v-icon>
              </v-btn>
              <span class="mx-4 text--text">{{ page }} of {{ data.pages }}</span>
              <v-btn outlined small min-width="28" color="text" class="ml-1 px-0" @click="nextPage">
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  data() {
    return {
      closeMenu: false,
      calendar: false,
      date: {
        start: this.$route.query.date_from || '',
        end: this.$route.query.date_to || '',
      },
      search: this.$route.query.src || '',
      customer: this.$route.query.customer || 'all',
      status: this.$route.query.status || 'all',
      page: Number(this.$route.query.page) || 1,
      sort: this.$route.query.sort ? this.$route.query.sort.split(',') : [],
      headers: [
        { text: 'Booking ID', value: 'booking_id', sortable: false },
        { text: 'Customer', value: 'customer', sortable: false },
        { text: 'Phone number/CIF', value: 'phone_number', sortable: false },
        { text: 'Amount', value: 'amount', sortable: false },
        { text: 'Status', value: 'status', sortable: false },
        { text: 'Date', value: 'created_at', sortable: false },
      ],
      itemsStatus: [
        { name: 'all', title: 'All' },
        { name: 'redeemed', title: 'Redeemed' },
      ],
      datePeriod: [
        { title: 'All' },
        { title: 'Today' },
        { title: 'Yesterday' },
        { title: 'This week' },
        { title: 'Last week' },
        { title: 'This month' },
        { title: 'Last month' },
        { title: 'This year' },
        { title: 'Last year' },
        { title: 'Select period' },
      ],
      masks: {
        input: 'DD.MM.YYYY',
      },
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      error: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    notifi(btn) {
      if (btn == 'search400') {
        this.$notify({
          title: 'Warning',
          message: 'This field must be at least 3 characters, maximum 10',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'sort') {
        this.$notify({
          title: 'Warning',
          message: 'Sorry, sort list invalid',
          type: 'warning',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == '500') {
        this.$notify({
          title: 'Something went wrong!',
          message: 'Sorry, something went wrong, please contact support',
          type: 'error',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    secectDate(i) {
      this.closeMenu = false;
      const year = new Date().getFullYear();
      const month = new Date().getMonth() + 1;
      const day = new Date().getDate();
      const d = new Date().getDay();
      if (i == 1) {
        this.date.start = `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
        this.date.end = `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
      } else if (i == 2) {
        this.date.start = `${year}-${month < 10 ? `0${month}` : month}-${day - 1 < 10 ? `0${day - 1}` : day - 1}`;
        this.date.end = `${year}-${month < 10 ? `0${month}` : month}-${day - 1 < 10 ? `0${day - 1}` : day - 1}`;
      } else if (i == 3) {
        this.date.start = `${year}-${month < 10 ? `0${month}` : month}-${day - d + 1 < 10 ? `0${day - d + 1}` : day - d + 1}`;
        this.date.end = `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
      } else if (i == 4) {
        this.date.start = `${year}-${month < 10 ? `0${month}` : month}-${day - d - 6 < 10 ? `0${day - d - 6}` : day - d - 6}`;
        this.date.end = `${year}-${month < 10 ? `0${month}` : month}-${day - d < 10 ? `0${day - d}` : day - d}`;
      } else if (i == 5) {
        this.date.start = `${year}-${month < 10 ? `0${month}` : month}-01`;
        this.date.end = `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
      } else if (i == 6) {
        this.date.start = `${year}-${month - 1 < 10 ? `0${month - 1}` : month - 1}-01`;
        this.date.end = `${year}-${month - 1 < 10 ? `0${month - 1}` : month - 1}-${this.getLastDayOfMonth(year, month - 2)}`;
      } else if (i == 7) {
        this.date.start = `${year}-01-01`;
        this.date.end = `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`;
      } else if (i == 8) {
        this.date.start = `${year - 1}-01-01`;
        this.date.end = `${year - 1}-12-31`;
      } else if (i == 9) {
        this.calendar = true;
        this.date.start = '';
        this.date.end = '';
      } else {
        this.date.start = '';
        this.date.end = '';
      }
      this.$nextTick(() => {
        i != 9 ? (this.closeMenu = true) : '';
        this.getData();
      });
    },
    closeCalendar() {
      this.closeMenu = true;
      this.date.start = '';
      this.date.end = '';
      this.getData();
    },
    getLastDayOfMonth(year, month) {
      const date = new Date(year, month + 1, 0);
      return date.getDate();
    },
    openLink(id) {
      this.$router.push(`/mokafaa/${id}`);
    },
    openCustomer(id) {
      this.$router.push(`/customers/${id}`);
    },
    openBooking(id) {
      this.$router.push(`/trip-bookings/${id}`);
    },
    sortItems(c) {
      if (this.sort.some((i) => i == `${c}_desc`)) {
        const idx = this.sort.indexOf(`${c}_desc`);
        this.sort.splice(idx, 1);
      } else if (this.sort.some((i) => i == `${c}_asc`)) {
        const idx = this.sort.indexOf(`${c}_asc`);
        this.sort.splice(idx, 1);
        this.sort.push(`${c}_desc`);
      } else {
        this.sort.push(`${c}_asc`);
      }
      this.$nextTick(() => {
        this.getData();
      });
    },
    clearSearch() {
      this.page = 1;
      this.search = '';
      this.getData();
    },
    nextPage() {
      if (this.page != this.data.pages) {
        this.page += 1;
        this.getData();
      }
    },
    formerPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.getData();
      }
    },
    async getData() {
      await this.$store
        .dispatch('getAnbList', {
          customer: this.customer,
          search: this.search,
          status: this.status,
          page: this.page,
          from: this.date.start,
          to: this.date.end,
          sort: this.sort,
        })
        .then(() => {
          this.nothing = false;
        })
        .catch((e) => {
          this.getDataError(e);
        });
    },
    getDataError(e) {
      this.statusError = e.response.status;
      if (e.response.data.error[0] === 'page__out_of_bounds') {
        this.page = 1;
        this.getData();
      } else if (e.response.data.error[0] === 'sort_list__invalid') {
        this.notifi('sort');
      } else if (this.statusError >= 400 && this.statusError < 500) {
        this.notifi('search400');
      } else if (this.statusError >= 500) {
        this.notifi('500');
      }
    },
  },
  computed: {
    data() {
      return this.$store.getters.anbList;
    },
    role() {
      return this.$store.getters.profile.role;
    },
  },
  destroyed() {
    this.$store.dispatch('setAnbList');
  },
};
</script>

<style lang="scss">
.anb-list {
  .btn-sort {
    width: 24px;
    height: 24px;
    border-radius: 4px;

    .v-icon {
      font-size: 16px;
    }
  }
}
</style>
